import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"


const SahkoinenIlmoittautuminenKoulutuspaiville = ({
  location: { pathname },
}) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Sähköinen ilmoittautuminen koulutuspäiville" />

    <div className="map-wraper full-container">
      <div className="map-wraper-in">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7894.457355066135!2d25.6143560979488!3d60.35248855201369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4691f5fc1bcb20f9%3A0x39bfbdc2516c27ba!2sHotel%20Haikko%20Manor%20%26%20Spa!5e0!3m2!1sen!2sfi!4v1738582263006!5m2!1sen!2sfi"
          width="100%"
          height="300"
          frameborder="0"
          allowFullScreen
          title="google-map"
        />
      </div>
    </div>


    <div id="content" className="content full pt-10 pb-10">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-8 col-md-offset-2">
            {/* <h1>Seuraavat koulutuspäivät Porvoossa 18.-19.3.2025</h1>
            <h3>Ilmoittautuminen avataan myöhemmin.</h3> */}
            <h1>Koulutuspäivät Porvoossa 18.-19.3.2025</h1>

            <h2>Osallistumismaksut ja majoitus</h2>

            <p className="font-bold">
              Koulutuspäivien osallistumismaksut:
            </p>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden">Koulutuspaketti ti-ke</div>
              <div class="w-1/3 overflow-hidden">yhdistyksen jäsen </div>
              <div class="w-1/3 overflow-hidden">310 eur</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">ei jäsen</div>
              <div class="w-1/3 overflow-hidden">360 eur</div>
            </div>
            <div class="flex flex-wrap overflow-hidden mt-3">
              <div class="w-1/3 overflow-hidden">Yksi päivä</div>
              <div class="w-1/3 overflow-hidden">yhdistyksen jäsen </div>
              <div class="w-1/3 overflow-hidden">210 eur</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">ei jäsen</div>
              <div class="w-1/3 overflow-hidden">250 eur</div>
            </div>
            <p>
              Hintoihin sisältyy koulutus, ruokailut sekä keskiviikon
              iltaohjelma.
            </p>
            <p>
              <b>Huonevaraukset:</b> huoneet ja ilmoittautuminen Haikon Kylpylähotellissa, ruokailut Kartanohotellissa</p>
            <p>Hotelli ja huoneluokka hinta/huone/yö:</p>

            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden">Huonehinnat</div>
              <div class="w-1/3 overflow-hidden">Kylpylähotellin Standard-huone yhdelle</div>
              <div class="w-1/3 overflow-hidden">149 eur/yö</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">Standard-huone kahdelle</div>
              <div class="w-1/3 overflow-hidden">154 eur/yö</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">
                Kylpylähotellin Premium-huone yhdelle
              </div>
              <div class="w-1/3 overflow-hidden">159 eur/yö</div>
            </div>
            <div class="flex flex-wrap overflow-hidden">
              <div class="w-1/3 overflow-hidden" />
              <div class="w-1/3 overflow-hidden">
                Premium-huone kahdelle
              </div>
              <div class="w-1/3 overflow-hidden">164 eur/yö</div>
            </div>
            <br />
            <p>
              {/* <p>
                Majoituskiintiö:  Radisson Blu Espoo Hotel 144 EUR / 1hh standard, 164 EUR / 2hh standard
                </p>
              */}
              <p>Laskutusosoitteeksi merkitään seurakunnan verkkolaskuosoite</p>
              <p>
                Sopimushintaisen majoituksen tilaisuutemme yhteyteen saat <b>vain varaamalla netistä www.haikko.fi koodilla ALLSHK, maksu paikan päällä.</b>
              </p>
              <p>Erikoishintaisia huoneita ei ole puhelimitse tai sähköpostitse saatavilla. Huonevaraukset tulee tehdä 03.03.2025 mennessä.</p>
              <p>Haikon Kartano on Helsingistä reilun ½ tunnin ajomatkan päässä, Porvoon keskustaan
                on matkaa noin viisi kilometriä. Haikossa on iso ja ilmainen parkkipaikka.
              </p>
              <p>
                <a href="https://www.porvoo.fi/asuminen-ymparisto/kadut-ja-liikenne/paikallis-ja-lahiliikenne/porvoon-joukkoliikenteen-talvikauden-aikataulut/" target="_blank">Tämä linkki ohjaa Porvoon joukkoliikenne aikatauluihin (kts myös ELY-linja 999 Tikkurila-Porvoo)</a>.  Porvoon keskustasta lähtevä bussi  on II-linjaa, lähtee yleensä Rauhankadun puolelta (Tolkkinen – Haikko)
              </p>
              <p>
                <b>VARAUS- JA PERUUTUSEHDOT</b> <br />
                Haikon varaus- ja peruutusehdot: <a href="https://www.haikko.fi/varausehdot-ryhmille" target="_blank">https://www.haikko.fi/varausehdot-ryhmille</a>
              </p>
              <p>Huonehintaan sisältyy buffetaamiainen, kuntosalin ja asiakassaunojen käyttö ja langaton Internetyhteys.<br />
                Huoneet ovat käytössänne tulopäivänä klo 15.00 jälkeen ja ne tulee luovuttaa lähtöpäivänä viimeistään klo 12.00. Huonevaraukset tulee tehdä mennessä.
              </p>
              <p>
                Majoituskiintiössä vieraat varaavat huoneensa ja huolehtivat majoituskustannuksistaan itse.
              </p>
              <p>
                <b>Ruokailuun liittyvät allergiat voitte ilmoittaa ilmoittautumislomakkeella.</b><br />
                <b>Haikon ohjelma on kotisivuillamme.</b>
              </p>


            </p>
          </div>
        </div>
        <div className="row pt-10 pb-10">
          <div className="col-md-5 col-md-offset-1">
            <h1>Sähköinen ilmoittautuminen</h1>

            <div className="spacer-45" />
          </div>
          <div className="col-md-5">
            <div className="contact-form-wrapper ">
              <h3>Ilmoittautumislomake</h3>
              <p className="margin-35">
                Vastaamme mielellämme kysymyksiin ja tiedusteluihin
              </p>
              <form
                name="sahkoinen ilmoittautuminen"
                method="POST"
                action="https://formspree.io/f/mbjnovdp"
                className="contact-form
                clearfix"
              >
                {/* <input
                  type="hidden"
                  name="_next"
                  value="https://shk.fi/kiitos"
                /> */}
                <input
                  type="hidden"
                  name="_subject"
                  value="Ilmoittautuminen koulutuspäiville"
                />

                <label for="nimi">Etunimi (vaaditaan)</label>
                <input
                  name="etuninimi"
                  type="text"
                  id="firstname"
                  className="form-control"
                  placeholder=""
                />
                <label for="nimi">Sukunimi (vaaditaan)</label>
                <input
                  name="sukunimi"
                  type="text"
                  id="lastname"
                  className="form-control"
                  placeholder=""
                />
                <label for="email">Sähköposti (vaaditaan)</label>
                <input
                  name="email"
                  type="text"
                  id="email"
                  className="form-control"
                  placeholder=""
                />
                <label for="email">Ammattinimike</label>
                <input
                  name="ammattinimike"
                  type="text"
                  id="ammattinimike"
                  className="form-control"
                  placeholder=""
                />
                <label for="puhelin">Puhelin</label>
                <input
                  name="puhelin"
                  type="text"
                  id="phone"
                  className="form-control"
                  placeholder=""
                />
                <label for="seurakunta">Seurakunta/yhteisö</label>
                <input
                  name="seurakunta"
                  type="text"
                  id="seurakunta"
                  className="form-control"
                  placeholder=""
                />
                <p>
                  Nimen ja seurakunnan/yhteisön julkaiseminen
                  osallistujaluettelossa: Järjestelyjä varten laaditaan
                  osallistujaluettelo.
                </p>
                <p className="font-bold">
                  Saako nimesi ja seurakuntasi/yhteisösi nimen julkaista
                  osallistujaluettelossa?
                </p>
                <div class="mb-4">
                  <label className="inline-flex items-center">
                    <input
                      name="julkaisulupa"
                      type="radio"
                      id="julkaisulupa"
                      className="form-radio"
                      value="julkaisulupa"
                      checked
                    />
                    <span class="ml-2">Annan julkaisuluvan</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      name="julkaisulupa"
                      type="radio"
                      id="eijulkaisulupaa"
                      value="eijulkaisulupaa"
                      className="form-radio"
                    />
                    <span class="ml-2">En anna julkaisulupaa</span>
                  </label>
                </div>
                <p>Osallistun</p>
                <div class="mb-4">
                  <label className="block items-center">
                    <input
                      name="osallistuu"
                      type="radio"
                      id="kokotapahtuma"
                      className="form-radio"
                      value="kokotapahtuma"
                      checked
                    />
                    <span class="ml-2">Koko koulutustapahtumaan</span>
                  </label>

                  <label className="block items-center">
                    <input
                      name="osallistuu"
                      type="radio"
                      id="vainti"
                      className="form-radio"
                      value="vainti"
                    />
                    <span class="ml-2">Vain ti 18.3.2025</span>
                  </label>
                  <label className="block items-center">
                    <input
                      name="osallistuu"
                      type="radio"
                      id="vainke"
                      className="form-radio"
                      value="vainke"
                    />
                    <span class="ml-2">Vain ke 19.3.2025</span>
                  </label>
                </div>
                <p>Yhdistyksen jäsenyys</p>
                <div class="mb-4">
                  <label className="block items-center">
                    <input
                      name="jasenyys"
                      type="radio"
                      id="shkjasen"
                      className="form-radio"
                      value="shkjasen"
                      checked
                    />
                    <span class="ml-2">Seurakunta/yhteisö SHK:n jäsen</span>
                  </label>

                  <label className="block items-center">
                    <input
                      name="jasenyys"
                      type="radio"
                      id="eishkjasen"
                      className="form-radio"
                      value="eishkjasen"
                    />
                    <span class="ml-2">Ei jäsen</span>
                  </label>
                </div>
                <label for="erityisruovakalio">
                  Mahdollinen erityisruokavalio
                </label>
                <input
                  name="erityisruokavalio"
                  type="text"
                  id="erityisruokavalio"
                  className="form-control"
                  placeholder=""
                />
                <p>Iltaruokailu 18.3.2025</p>
                <div class="mb-4">
                  <label className="block items-center">
                    <input
                      name="iltaruokailu"
                      type="radio"
                      id="osallistuuruokailuun"
                      className="form-radio"
                      value="osallistuuruokailuun"
                      checked
                    />
                    <span class="ml-2">
                      Osallistun iltaruokailuun
                    </span>
                  </label>

                  <label className="block items-center">
                    <input
                      name="iltaruokailu"
                      type="radio"
                      id="enosallisturuokailuun"
                      className="form-radio"
                      value="enosallisturuokailuun"
                    />
                    <span class="ml-2">En osallistu iltaruokailuun</span>
                  </label>
                </div>
                <label for="laskutusosoite">
                  Laskutusosoite (Kipa-osoite, mikäli SRK maksaa laskun)
                  (vaaditaan)
                </label>
                <textarea
                  name="laskutusosoite"
                  type="textarea"
                  id="laskutusosoite"
                  rows="3"
                  className="form-control"
                  placeholder=""
                />
                <label for="henkilo">Henkilö</label>
                <input
                  name="henkilo"
                  type="text"
                  id="henkilo"
                  className="form-control"
                  placeholder=""
                />
                <label for="viesti">Viestisi (vaaditaan)</label>
                <textarea
                  name="viesti"
                  cols="2"
                  rows="2"
                  id="comments"
                  className="form-control"
                  placeholder=""
                />
                <input
                  type="submit"
                  className="button btn-primary"
                  value="Lähetä"
                />
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div className="spacer-60" />
  </Layout>
)

export default SahkoinenIlmoittautuminenKoulutuspaiville
